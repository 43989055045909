import React from "react";

const Footer = () => {
  return (
    <footer className="fixed p-2 px-4 text-center -translate-x-1/2 rounded-lg print:bottom-1 print:right-1 print:left-auto print:translate-x-0 print:bg-transparent print:text-sm bottom-4 left-1/2 bg-gray-500/25 backdrop-blur-md">
      Updated in July, 2023
    </footer>
  );
};

export default Footer;
